<template>
  <div>
    <van-nav-bar
      title="意见及诉求"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-field
      v-model="postForm.message"
      rows="2"
      autosize
      label="留言"
      type="textarea"
      maxlength="200"
      placeholder="请输入留言"
      show-word-limit
      required
    />
    <div>
      反馈人:
    </div>
    <input type="text" class="Ipt" v-model="postForm.name">
    <div>
      联系电话:
    </div>
    <input type="text" class="Ipt" v-model="postForm.phone">
    <button class="subBtn" @click="submit">提交</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      postForm:{
        message: "",
        name:'',
        phone:''
      }
      
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    submit(){
      if(this.postForm.message===""||this.postForm.name===""||this.postForm.phone===""){
        this.$toast("提交失败,请填写完整后提交")
      }else{
        this.$toast("提交成功")
      }
    }
  },
};
</script>

<style scoped>
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
 .van-nav-bar__content {
  height: 100%;
}
 .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
 .van-icon-arrow-left,
 .van-nav-bar__text {
  font-size: 0.14rem;
}
 .van-cell,
 .van-field__word-limit {
  font-size: 0.14rem;
}
 .van-field__body,.van-field__control {
  height: 1rem !important;
}
.van-field__control {
  padding: 0.05rem;
  line-height: 0.16rem;
  background-color: #eee;
}
.Ipt{
  width: 98%;
  height: 0.3rem;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  margin-left: 1%;
  background-color: #eee;
  border: none;
}
.subBtn{
  width: 96%;
  height: 0.3rem;
  margin-left: 2%;
  background-color: #1989fa;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
}
</style>